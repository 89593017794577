body{
    overflow-x: hidden;
}
.Home{
    height: 200vh;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/pratitya-6b78c.appspot.com/o/home-new-blend.png?alt=media&token=3873fb5f-b0f3-44ef-a13e-d16738d44589");
    background-size: cover;
    background-repeat: no-repeat;
}

.Home{
    font-family: arab;
    color: #E9C79A;
}

.r1{
    padding-top: 70px;
    height: 100vh;
}

.r2{
    padding: 50px;
    height: 80vh;
}

.r3{
    padding: 50px;
    height: auto;
    background-color: #1a0300;
}

.r2-head,.r3-head{
    display: flex;
    align-items: center;
    height: 100px;
    justify-content: center;
    padding-right: 80px;
    font-size: 70px;
}
.empty{
    background-color: #fff;
    height: 8vh;
    background: rgb(0, 0, 0);
    background: rgb(0, 0, 0);
    background: linear-gradient(to bottom, black,#1a0300);
}
.r2-content{
    padding-top:60px ;
    display: flex;
    width: 100%;
}
.r2-text{
    font-family: sans-serif;
    width: 30vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    text-align: center;
}
.r2-carousel{
    display: flex;
    justify-content: start;
    width: 50vw;
    height: 50vh;
}
.r2-carousel iframe{
    display: flex;
    border-radius: 20px; 
}
.slide{
    display: flex;
    justify-content: start;
}
.r2-content{
    display: flex;
    gap: 10px;
    justify-content: center;
}
.r2-carousel{
    width: 50vw;
    display: flex;
    justify-content: start;
}

@media (max-width: 600px) {
    .r1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Home{
        background-color: #000009;
        background-image: url("https://firebasestorage.googleapis.com/v0/b/pratitya-6b78c.appspot.com/o/home-small.png?alt=media&token=ebbd2940-e4f8-4bb5-816f-e55123508e53");
        background-size: contain;
        background-repeat: no-repeat;
    }

    .r2{
        height: auto;
        background-color: #1a0300;
    }
    .r2-text{
        display: block;
        width: auto;
        height: auto;
        font-size: 18px;
        text-align: center;
        order: 2;
    }
    .r2-content{overflow:hidden;
        align-items: center;
        padding-top: 0;
        flex-direction: column;
    }
    .r2-carousel{
        width: 100%;
        
    }
    .r2-carousel iframe{
        width: 100%;
        height: auto;
        border-radius: 20px;
        order: 1;
    }

}

@media (min-width: 601px) and (max-width: 1300px) {
  
    .r2-text{
      display: none;}
}

@media (min-width: 601px) and (max-width: 1300px) {
  
    .r2-text{
      display: none;   }
}
@media (min-height : 601px) and (max-height: 1300px){
    .r2-text{
       
        font-size: 20px;
    
    }

    
}
@media(min-height: 100px) and (max-height: 600px){
    .r2-carousel{
        display: none;
    }
    .r2-text{
        display: none;
    }
    .r2-head{
        display: none;
    }
    .r2{
        padding: 0;
    }
    .empty{
        display:none;
}

}