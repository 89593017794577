.card-container {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr 1fr;
  }


  @media (max-width: 600px) {
    .card-container {
      display: flex;
      flex-direction: column;
    }

    .card{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }