.footer {
    margin-top: -20px;
    font-family: sans-serif;
    font-weight: 400;
    gap: 15px;
    width: 100vw;
    padding: 20px 0;
    background: rgb(0, 0, 0);
    background: rgb(0, 0, 0);
    background: linear-gradient(to top, rgb(153,48,23),#1a0300);
    max-height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    overflow-x: hidden;
  }
  .footer .social {
    text-align: center;
  
    color: white;
  }
  a{
    text-decoration: none;
    color: white;
  }
  .footer .social a {
    font-size: 24px;
    color: inherit;
  
    width: 40px;
    height: 40px;
    line-height: 38px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    margin: 0 8px;
    opacity: 1;
  }
  
  .footer ul {
    margin-top: 0;
    padding: 0;
    list-style: none;
    color: #fff;
    font-size: small;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 0;
    font-weight: 400;
  }
  .footer ul li .anc {
    color: inherit;
    text-decoration: none;
    opacity: 1;
  }
  footer ul li {
    display: inline-block;
    padding: 0 15px;
  }
  .footer ul li .anc:hover {
    opacity: 0.5;
  }
  .footer p {
    text-align: center;
    font-size: 13px;
    color: #fff;
    margin-bottom: 5px;
  }
    
  .copy{
    color: #fff;
    text-decoration: none;
    background-color: rgb(0, 0, 0,0.6);
    padding: 8px;
    border-radius: 5px;
    border: 1px solid white;
    transition: all 0.2s ease-in;
  }
  
  .copy {
    font-size: 20px;
    font-weight: 500;
  }
  #pb{
    margin-bottom: 25px;
  }
  .copy:hover{
    color: #8C2725;
    background-color: #fff;
  }
  
    @media screen and (max-width: 1050px) {
      .footer{
      flex-direction: column;
      }
    }