.Navbar{
    position: absolute;
    height: 70px;
    z-index: 100;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}

.logo-class {
    width: 50vw;
    flex: 1fr;
    display: flex;
    justify-content: space-between;
}
.nav-links {
    flex: 1fr;
    width: 50vw;
    display: flex;
    justify-content: end;
    gap: 20px;
}

.Navbar .logo{
    width: 100px;
    height: 120px;
    transition: all 250ms;
}
.Navbar .logo:hover{
cursor: pointer;
transform: scale(1.2);
}
.Navbar .sg-logo-pc{
    width:150px;
    /* padding-top: 30px; */
    padding-left: 20px;
    transition: all 250ms;
}
.sg-logo-mobi{
    display: none;
}
.nav-links a{
    scroll-behavior: smooth;
}
.Navbar .sg-logo-pc:hover{
    cursor: pointer;
    transform: scale(1.05);
}
.nav-links p,.nav-links a {
    color: #fff;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-family: arab;
    font-size: 30px;
    color: #E9C79A;
    transition: all 250ms;
}

.nav-links p:hover,.nav-links a:hover{
    cursor: pointer;
    font-size: 35px;
}
.ham{padding-top: 30px;
    display: none;
}

@media (max-width: 600px) {
    .sg-logo-pc{
        display: none;
    }
    .sg-logo-mobi{
        display: block;
    }
    .sg-logo-mobi{
        width:30px;
        /* padding-top: 30px; */
        padding-left: 20px;
        transition: all 250ms;
    }

    .Navbar{
        justify-content: space-between;
    }
    .logo-class{
        padding: 0;
    }
    .logo{
        position: absolute;
        left: 38%;
        top: -20px;
    }
    .nav-links {
        display: none;
    }
    .ham{
        display: block;
        margin-right: 20px;
        margin-top: -30px;
    }
    .hidden-menu{

        position: absolute;
        right: 0;
        top: 90px;
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: end;
        padding-right: 20px;
        background-color: rgba(255,255,255,0.3);
    }
    .hidden-menu p{
        font-size: 30px;
        font-family: arab;
        color: #E9C79A;
        line-height: 60px;
    }

}