.contact{
    padding-top: 4rem;  
    padding-bottom: 2rem;
    background-color: black;
    color: white;
}
.contact-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4rem;
    padding-right: 2rem;
    margin-top: 10vh;
}
.contact-heading h2{
    color: #8C2725;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 2px;
    position:relative;
    font-family: "Bai Jamjuree",sans-serif;
}
.contact-heading h2::before{
    position: absolute;
    top: 50%;
    right: -80px;
    content: '';
    width: 70px;
    height: 2px;
    background: #8C2725;
}
.contact-heading h2::after{
    position: absolute;
    top: 50%;
    left: -80px;
    content: '';
    width: 70px;
    height: 2px;
    background: #8C2725;
} 
.container {
    max-width: 1170px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: auto;
}
.row{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.column{
    flex: 0 0 auto;
    width: 50%;
}
.contact-widget{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-bottom: 40px;
    padding-right: 10px;
}
.contact-widget-item{
    display: flex;
    align-items: center;
    overflow: hidden;
}

.icon{
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
}
.icon i{
    font-size: 24px;
    color: #fff;
    position: relative;
    top: 3px;
}
.text{
    overflow: hidden;
}
.text h5{
    color: white;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 6px;
}
.text p{
    font-size: 16px;
    font-family: "Bai Jamjuree",sans-serif;
    color: white;
    font-weight: 400;
    line-height: 28px;
    margin: 0 0 15px 0;
}
.contact-form{
    text-align: center;
    margin-bottom: 40px;
}
form{
    display: block;
    margin-top: 0em;
}
form input{
    height: 46px;
    width: 100%;
    outline: 1px solid #8C2725;
    padding-left: 20px;
    font-size: 15px;
    color: #111;
    border: 1px solid #e1e1e1;
    margin-bottom: 20px;
    border-radius: 4px;
    -webkit-transition: all , .3s;
    -o-transition: all , .3s;
    transition: all , .3s;
}
form textarea{
    height: 110px;
    width: 100%;
    padding-left: 20px;
    padding-top: 12px;
    outline: none;
    font-size: 15px;
    color: #111;
    outline: 1px solid #8C2725;
    resize: none;
    margin-bottom: 9px;
    border-radius: 4px;
    -webkit-transition: all , .3s;
    -o-transition: all , .3s;
    transition: all , .3s;
}
form button{
    width: 100%;
    cursor: pointer;
}
.site-btn{
    border: none;
    display: inline-block;
    background: #8C2725;
    font-size: 15px;
    font-family: "Rubik",sans-serif;
    font-weight: bold;
    color: white;
    width: 250px;
    height: 50px;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 50px;
    padding: 12px 40px 10px;
    transition: all 0.2s ease-in;
}
.site-btn:hover{
    background-color: #fff;
    color: #8C2725;
    border: 1px solid #8C2725;
}
.column-map{
    flex: 0 0 auto;
    width: 100%;
}
.contact-map iframe{
    width: 100%;
}
@media screen and (max-width:1200px) {
    .container {
        max-width: 960px;
    }
}
@media screen and (max-width:990px) {
    .container {
        max-width: 720px;
    }
}
@media (max-width: 768px){
   .container {
    max-width: 540px;
   }
   .column{
    width: 100%;
   }
}
@media (max-width: 500px){
    .contact-heading{
        padding-right: 0;
    }
    .contact-heading h2{
        font-size: 22px; 
    }
    .contact-heading h2::before{
        right: -60px;
        width: 50px;
    }
    .contact-heading h2::after{
        left: -60px;
        width: 50px;
    } 
}